import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import Sidebar from '../../components/Layout/SidebarVendor';
import {
  StripePayment,getEventList, getProfile
} from "../../store/slices/userSlice";


const Featured = () => {

  const stripePromise = loadStripe('pk_test_51PqU1zF0bLXXIpJTh0mYsKGrQLjc3DueejgxU3JoF2zX6rcpVpJ0TbEZXS8PZTn9Z86xxZgd0nsZhtBkM62tMSLU00hbrzCAQr'); // Replace with your Publishable Key
  const myEvents = useSelector(getEventList);
  const dispatch = useDispatch();
  const profile = useSelector(getProfile);

  console.log('profile', profile)
  console.log('myEvents', myEvents)

  const handleClick = async () => {
    try {

      console.log("first")
      const session = await dispatch(StripePayment({storeId: myEvents?._id, userId: profile?._id, customerId: profile?.stripe_id})).unwrap();

     
      const stripe = await stripePromise;
  
      
      const { error } = await stripe.redirectToCheckout({
        sessionId: session.id, 
      });
        if (error) {
            console.error("Stripe Checkout Error:", error);
        }
        
    } catch (error) {
        console.error("Error during payment process:", error);
    }
};


  return (
    <section className="wf-vendor-dashboard">
      <div className="container-fluid">
        <div className="d-flex position-relative">
          <Sidebar />
          <section className="home-section">
            {/* Heading */}
            <div className='row mb-4'>
              <div className='col-12'>
                <h2 className="font-size-30 dm-font purple-color mb-0">Featured</h2>
              </div>
            </div>
            <div className='text-center wf-shadow-2 p-5 white-bg rounded'>
              <h3 className="font-size-28 pink-color mb-3 dm-font">$299</h3>
              <button
                className="btn wf-btn wf-purple-btn"
                onClick={handleClick}
              >
                Buy Featured Subscription
              </button>
            </div>
          </section>
        </div>
      </div>
    </section>
  );
};

export default Featured;
